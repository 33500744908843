import styles from "./footer.module.css";
import { getSubDomainOfPage } from "../services/helper";
import Link from "next/link";
import { useStoreContext } from "../contexts/StoreDataContext";
import { useContext } from "react";

const Footer = () => {
  const storeData = useStoreContext();

  const navigateToHome = () => {
    const subdomain = getSubDomainOfPage();
    const url = `https://dm2buy.com?utm_source=footer&utm_campaign=${subdomain}`;
    window.open(url, "_blank").focus();
  };

  // This block because {terms, contacts ...} cannot be deconstructed from undefined 'legealInfo'
  const isNoLegaInfoAvailable = !storeData?.legalInfo;
  if (isNoLegaInfoAvailable)
    return (
      <footer className={styles.footer}>
        {/* <Image src="/instagram-4.png" width="24" height="24" /> */}
        <div className={styles.tagline} onClick={navigateToHome}>
          dm2buy
        </div>

      </footer>
    );

  const { terms, contacts, returns, privacy, about, policy, care, size } = storeData?.legalInfo;
  const isNoLegalDataAvailable =
    !terms && !contacts && !returns && !privacy && !about && !policy && !care && !size;

  if (isNoLegalDataAvailable)
    return (
      <footer className={styles.footer}>
        {/* <Image src="/instagram-4.png" width="24" height="24" /> */}
        <div className={styles.tagline} onClick={navigateToHome}>
          dm2buy
        </div>
      </footer>
    );
  return (
    <footer className={styles.footer}>
      {/* <Image src="/instagram-4.png" width="24" height="24" /> */}
      <div className={styles.tagline} onClick={navigateToHome}>
        dm2buy
      </div>
      <div className={styles.footerLinks}>
        {policy && <Link href="/policy">Policy</Link>}
        {care && <Link href="/care">Care</Link>}
        {size && <Link href="/size">Size</Link>}
        {terms && <Link href="/terms">Terms</Link>}
        {returns && <Link href="/returns">Returns</Link>}
        {privacy && <Link href="/privacy">Privacy</Link>}
        {contacts && <Link href="/contact">Contact</Link>}
        {about && <Link href="/about">About</Link>}
      </div>
    </footer>
  );
};

export default Footer;
